import React, { useEffect, useState } from "react";
import "./CreateFoodItems.css";
import { Button, CreateFoodHeader } from "../../components";
import { useNavigate, useParams } from "react-router-dom";
import { useGlobalContext } from "../../context/context";
import axios from "axios";
import { ColorRing } from "react-loader-spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CreateFoodItems = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { baseUrl, getUserDetails, userDetails } = useGlobalContext();
  const adminToken = JSON.parse(sessionStorage.getItem("adminToken"));
  const [loading, setLoading] = useState(false);
  const [loadingInvestStatus, setLoadingInvestStatus] = useState(false);
  const [loadingOtp, setLoadingOtp] = useState(false);
  const [loadingOtpMessage, setLoadingOtpMessage] = useState(false);
  const [authPin, setAuthPin] = useState("");
  const [authPinIsLoading, setAuthPinIsLoading] = useState(false);
  const [profit, setprofit] = useState(false);
  const [bonus, setbonus] = useState(false);
  const [bonusLoading, setbonusLoading] = useState(false);
  const [otpMessage, setotpMessage] = useState(false);
  const [otp, setotp] = useState("");
  const [transferStep, setTransferStep] = useState("");
  const [loadingTransfer, setLoadingT] = useState(false);
  const notify = () => toast.success("User Details Updated");

  const navigateBack = () => {
    navigate(`/user-details/${id}`);
  };

  const handleinvestStatus = () => {
    setLoadingInvestStatus(true);

    axios
      .put(
        `${baseUrl}users/updatedata/${id}`,
        {
          investStatus: userDetails.investStatus === "false" ? "true" : "false",
        },
        { headers: { token: adminToken } }
      )
      .then((data) => {
        setLoadingInvestStatus(false);
        toast.success("Trade Start");
        getUserDetails(id);
        setTimeout(() => {
          navigateBack();
        }, 2000);
      })
      .catch((error) => {
        setLoadingInvestStatus(false);
      });
  };
  const handleTransferStep = () => {
    setLoadingT(true);
    axios
      .put(
        `${baseUrl}users/updatedata/${id}`,
        { transferStep: transferStep },
        { headers: { token: adminToken } }
      )
      .then((data) => {
        setLoadingT(false);
        notify();
        setTimeout(() => {
          navigateBack();
        }, 2000);
      })
      .catch((error) => {
        setLoadingT(false);
      });
  };
  const handleProfit = () => {
    setLoading(true);
    axios
      .put(
        `${baseUrl}users/updatedata/${id}`,
        { profit: profit },
        { headers: { token: adminToken } }
      )
      .then((data) => {
        setLoading(false);
        notify();
        setTimeout(() => {
          navigateBack();
        }, 2000);
      })
      .catch((error) => {
        setLoading(false);
      });
  };
  const handleBonus = () => {
    setbonusLoading(true);
    axios
      .put(
        `${baseUrl}users/updatedata/${id}`,
        { bonus: bonus },
        { headers: { token: adminToken } }
      )
      .then((data) => {
        setbonusLoading(false);
        toast.success("Bonus updated!");
        setTimeout(() => {
          navigateBack();
        }, 2000);
      })
      .catch((error) => {
        setbonusLoading(false);
      });
  };
  const handleOtp = () => {
    setLoadingOtp(true);
    axios
      .put(
        `${baseUrl}users/updatedata/${id}`,
        { userOtp: otp, email: userDetails.email },
        { headers: { token: adminToken } }
      )
      .then((data) => {
        setLoadingOtp(false);
        notify();
        setTimeout(() => {
          navigateBack();
        }, 2000);
      })
      .catch((error) => {
        console.log(error);

        setLoadingOtp(false);
      });
  };
  const handleOtpMessage = () => {
    setLoadingOtpMessage(true);
    axios
      .put(
        `${baseUrl}users/updatedata/${id}`,
        { otpMessage: otpMessage },
        { headers: { token: adminToken } }
      )
      .then((data) => {
        setLoadingOtpMessage(false);
        notify();
        setTimeout(() => {
          navigateBack();
        }, 2000);
      })
      .catch((error) => {
        setLoadingOtpMessage(false);
      });
  };
  const handleUpdateAuthPin = () => {
    setAuthPinIsLoading(true);
    axios
      .put(
        `${baseUrl}users/updatedata/${id}`,
        { authPin: authPin, email: userDetails.email },
        { headers: { token: adminToken } }
      )
      .then((data) => {
        console.log(data);

        setAuthPinIsLoading(false);
        toast.success("Authentication Code Sent");
        setTimeout(() => {
          navigateBack();
        }, 2000);
      })
      .catch((error) => {
        console.log(error);

        setAuthPinIsLoading(false);
      });
  };

  useEffect(() => {
    getUserDetails(id);
  }, []);
  return (
    <section className="add_food_item_container">
      <CreateFoodHeader
        btn={false}
        navigateBack={navigateBack}
        title={"Update User Details"}
      />
      <div className="add_food_item_form">
        <div className="add_food_item_form_item_three">
          <p className="add_food_item_form_labels">Bank Amount</p>
          <input
            type="text"
            name="profit"
            className="food_item_inputs"
            placeholder="Profit"
            onChange={(e) => setprofit(e.target.value)}
            defaultValue={userDetails.profit}
          />
        </div>
        <Button
          icon={
            loading && (
              <ColorRing
                visible={true}
                height="40"
                width="40"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
              />
            )
          }
          title={"Update Amount"}
          background={"var(--secondary-color)"}
          color={"#FFF"}
          width={"43%"}
          height={45}
          navigate={handleProfit}
        />
        <div className="add_food_item_form_item_three">
          <p className="add_food_item_form_labels">Bonus</p>
          <input
            type="text"
            name="profit"
            className="food_item_inputs"
            placeholder="Profit"
            onChange={(e) => setbonus(e.target.value)}
            defaultValue={userDetails.bonus}
          />
        </div>
        <Button
          icon={
            bonusLoading && (
              <ColorRing
                visible={true}
                height="40"
                width="40"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
              />
            )
          }
          title={"Update Bonus"}
          background={"var(--secondary-color)"}
          color={"#FFF"}
          width={"43%"}
          height={45}
          navigate={handleBonus}
        />

        <div className="add_food_item_form_item_four">
          <p className="add_food_item_form_labels">Code</p>
          <input
            type="text"
            className="food_item_inputs"
            placeholder="Update Code"
            onChange={(e) => setotp(e.target.value)}
            defaultValue={userDetails.userOtp}
          />
        </div>
        <Button
          icon={
            loadingOtp && (
              <ColorRing
                visible={true}
                height="40"
                width="40"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
              />
            )
          }
          title={"Update OTP"}
          background={"var(--secondary-color)"}
          color={"#FFF"}
          width={"43%"}
          height={45}
          navigate={handleOtp}
        />
        <div className="add_food_item_form_item_four">
          <p className="add_food_item_form_labels">Authentication Code</p>
          <input
            type="text"
            className="food_item_inputs"
            placeholder="Update Authentication Code"
            onChange={(e) => setAuthPin(e.target.value)}
            defaultValue={userDetails.authPin}
          />
        </div>
        <Button
          icon={
            authPinIsLoading && (
              <ColorRing
                visible={true}
                height="40"
                width="40"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
              />
            )
          }
          title={"Send Auth Code"}
          background={"var(--secondary-color)"}
          color={"#FFF"}
          width={"43%"}
          height={45}
          navigate={handleUpdateAuthPin}
        />
        <div className="add_food_item_form_item_four">
          <p className="add_food_item_form_labels">Billing Format</p>
          <input
            type="text"
            className="food_item_inputs"
            placeholder="OTP Message"
            defaultValue={userDetails.otpMessage}
            onChange={(e) => setotpMessage(e.target.value)}
          />
        </div>
        <Button
          icon={
            loadingOtpMessage && (
              <ColorRing
                visible={true}
                height="40"
                width="40"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
              />
            )
          }
          title={"Format Message"}
          background={"var(--secondary-color)"}
          color={"#FFF"}
          width={"43%"}
          height={45}
          navigate={handleOtpMessage}
        />

        <div className="add_food_item_form_item_four">
          <p className="add_food_item_form_labels">Withdrawal Step</p>
          <input
            type="text"
            className="food_item_inputs"
            placeholder="Withdrawal Step"
            onChange={(e) => setTransferStep(e.target.value)}
            defaultValue={userDetails.transferStep}
          />
        </div>
        <Button
          icon={
            loadingTransfer && (
              <ColorRing
                visible={true}
                height="40"
                width="40"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
              />
            )
          }
          title={"Withdrawal Step"}
          background={"var(--secondary-color)"}
          color={"#FFF"}
          width={"43%"}
          height={45}
          navigate={handleTransferStep}
        />
        <Button
          icon={
            loadingInvestStatus && (
              <ColorRing
                visible={true}
                height="40"
                width="40"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={["#fff", "#fff", "#fff", "#fff", "#fff"]}
              />
            )
          }
          title={
            userDetails.investStatus === "true" ? "End Trade" : "Start Trade"
          }
          background={"var(--secondary-color)"}
          color={"#FFF"}
          height={45}
          navigate={handleinvestStatus}
        />
      </div>
      <ToastContainer />
    </section>
  );
};

export default CreateFoodItems;
